import React, { useState, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import ImportacaoService from '../../services/importacaoservice';
import 'bootstrap/dist/css/bootstrap.min.css';
import Papa from 'papaparse';
import tokenService from "../../services/token.service";
import LoadingBar from 'react-top-loading-bar';

const ImportacaoTable = ({ columns, data, pageCount, pageIndex, setPageIndex, selectedColumns, handleColumnSelect }) => {
    const {
        getTableProps,
        getTableBodyProps,
        rows,
        prepareRow,
        gotoPage,
        state: { pageIndex: tablePageIndex }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex },
            manualPagination: true, // Controle manual de paginação
            pageCount
        },
        usePagination
    );

    const handlePageClick = (number) => {
        setPageIndex(number);
        gotoPage(number);
    };

    const handleNextPage = () => {
        if (tablePageIndex < pageCount - 1) {
            setPageIndex(tablePageIndex + 1);
            gotoPage(tablePageIndex + 1);
        }
    };

    const handlePreviousPage = () => {
        if (tablePageIndex > 0) {
            setPageIndex(tablePageIndex - 1);
            gotoPage(tablePageIndex - 1);
        }
    };

    // Centraliza a página de acordo com a página atual
    const startPage = Math.max(tablePageIndex - 2, 0);
    const endPage = Math.min(startPage + 4, pageCount - 1); // Mostra até 5 páginas
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
        <>
            <table {...getTableProps()} className="table">
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column.accessor}>
                                <input
                                    type="checkbox"
                                    id={column.accessor}
                                    checked={selectedColumns.includes(column.accessor)}
                                    onChange={() => handleColumnSelect(column.accessor)}
                                />
                                <label htmlFor={column.accessor} style={{ color: 'black' }}>{column.header}</label>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr key={row.id}>
                                {row.cells.map(cell => (
                                    <td key={cell.column.id}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="pagination">
                        <button
                            onClick={handlePreviousPage}
                            disabled={tablePageIndex === 0} // Desativa a seta esquerda na primeira página
                            style={{ opacity: tablePageIndex === 0 ? 0.5 : 1 }}
                        >
                            {'<'}
                        </button>{' '}
                        {pageNumbers.map(number => (
                            <button
                                key={number}
                                onClick={() => handlePageClick(number)}
                                disabled={number === tablePageIndex} // Destaque para a página atual
                                style={{
                                    fontWeight: number === tablePageIndex ? 'bold' : 'normal',
                                    textDecoration: number === tablePageIndex ? 'underline' : 'none'
                                }}
                            >
                                {number + 1}
                            </button>
                        ))}
                        <button
                            onClick={handleNextPage}
                            disabled={tablePageIndex === pageCount - 1 || pageCount === 0} // Desativa a seta direita na última página ou sem resultados
                            style={{ opacity: tablePageIndex === pageCount - 1 || pageCount === 0 ? 0.5 : 1 }}
                        >
                            {'>'}
                        </button>{' '}
                    </div>
                </div>
            </div>
        </>
    );
};

const ImportacaoPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage] = useState(20); // Número de itens por página
    const [filters, setFilters] = useState({});
    const [currentFilters, setCurrentFilters] = useState({});
    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const [pageIndex, setPageIndex] = useState(0); // Controle de paginação
    const [selectedColumns, setSelectedColumns] = useState([]);

    const user = tokenService.getUserData();

    const checkPermission = (requested) => {
        return user.permissoes.some((pi) => pi.includes(requested));
    };

    const columns = React.useMemo(
        () => [
            { header: 'CNPJ', accessor: 'cnpj' },
            { header: 'Razão Social', accessor: 'razaoSocial' },
            { header: 'Situação Cadastral', accessor: 'situacaoCadastral' },
            { header: 'CNAE', accessor: 'cnae' },
            { header: 'Tipo', accessor: 'tipo' },
            { header: 'Endereço', accessor: 'endereco' },
            { header: 'Cidade', accessor: 'cidade' },
            { header: 'Rua', accessor: 'rua' },
            { header: 'Complemento', accessor: 'complemento' },
            { header: 'Bairro', accessor: 'bairro' },
            { header: 'CEP', accessor: 'cep' },
            { header: 'Estado', accessor: 'estado' },
            { header: 'DDD', accessor: 'ddd' },
            { header: 'Telefone', accessor: 'telefone' },
            { header: 'DDD2', accessor: 'ddd2' },
            { header: 'Telefone2', accessor: 'telefone2' },
            { header: 'DDD3', accessor: 'ddd3' },
            { header: 'Telefone3', accessor: 'telefone3' },
            { header: 'Email', accessor: 'email' },
        ],
        []
    );

    useEffect(() => {
        setSelectedColumns(columns.map(column => column.accessor));
    }, [columns]);

    const fetchData = (page, appliedFilters) => {
        setLoading(true);
        setProgress(50);
        ImportacaoService.getListarTodos(page + 1, itemsPerPage, appliedFilters, false)
            .then(response => {
                if (response.data && response.data.lista) {
                    setData(response.data.lista);
                    setPageCount(Math.ceil(response.data.total / itemsPerPage));
                    setMessage('');
                } else {
                    setData([]);
                    setPageCount(0);
                    setMessage('Nenhum dado retornado');
                }
                setLoading(false);
                setProgress(100);
            })
            .catch(error => {
                setData([]);
                setPageCount(0);
                setLoading(false);
                setProgress(0);
                setMessage('Erro ao buscar dados');
            });
    };

    const handleSearch = () => {
        setFilters(currentFilters);
        setPageIndex(0); // Volta para a primeira página após buscar
        fetchData(0, currentFilters); // Executa a busca ao clicar no botão "Buscar"
    };

    const handlePageChange = (newPageIndex) => {
        setPageIndex(newPageIndex);
        fetchData(newPageIndex, filters); // Busca ao clicar na paginação
    };

    const handleFilterChange = (filterId, event) => {
        setCurrentFilters(prevFilters => ({
            ...prevFilters,
            [filterId]: event.target.value
        }));
    };

    const handleColumnSelect = (column) => {
        setSelectedColumns(prevColumns => {
            if (prevColumns.includes(column)) {
                return prevColumns.filter(col => col !== column);
            } else {
                return [...prevColumns, column];
            }
        });
    };

    // Função para exportar todos os dados, ignorando a paginação
    const handleExport = () => {
        setLoading(true);
        ImportacaoService.getListarTodos(1, 999999, filters, true) // Ignora paginação
            .then(response => {
                if (response.data && response.data.lista) {
                    const exportedData = response.data.lista.map(row =>
                        selectedColumns.reduce((acc, column) => {
                            acc[column] = row[column];
                            return acc;
                        }, {})
                    );

                    const csv = Papa.unparse(exportedData, {
                        header: true,
                        encoding: 'UTF-8'
                    });
                    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const csvURL = window.URL.createObjectURL(csvData);
                    let tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', 'export.csv');
                    tempLink.click();
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setMessage('Erro ao exportar dados');
            });
    };

    return (
        <>
            <header className="jumbotron col-md-10 mr-auto ml-auto">
                <h3>Listar Importações</h3>
            </header>
            <div className="container">
                {checkPermission("cadastrar-importacao") && (
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button onClick={() => window.location.href = "/importacao/cadastrar"} className="btn btn-primary btn-md mb-3">
                                Cadastrar Importação
                            </button>
                        </div>
                    </div>
                )}
                {checkPermission("atualizar-cep-importacao") && (
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button onClick={ImportacaoService.updateCEPImportacao} className="btn btn-primary btn-md mb-3">
                                Atualizar CEP
                            </button>
                        </div>
                    </div>
                )}
                <div className="row">
                    {columns.map((column) => (
                        <div key={column.accessor} className="col-md-3">
                            <div className="form-group">
                                <label>{column.header}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name={column.accessor}
                                    value={currentFilters[column.accessor] || ''}
                                    onChange={event => handleFilterChange(column.accessor, event)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <LoadingBar color='#f11946' progress={progress} onLoaderFinished={() => setProgress(0)} />
                <div className="row mb-3">
                    <div className="col-md-6 offset-md-3 text-center">
                        <button className="btn btn-primary w-100" onClick={handleSearch} disabled={loading}>Buscar</button>
                    </div>
                </div>
                {message && <div className="alert alert-success">{message}</div>}
            </div>
            <div className="container-fluid">
                <ImportacaoTable
                    columns={columns}
                    data={data}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    setPageIndex={handlePageChange}
                    selectedColumns={selectedColumns}
                    handleColumnSelect={handleColumnSelect}
                />
                <button className="btn btn-secondary w-100" onClick={handleExport} disabled={loading}>Exportar</button>
            </div>
        </>
    );
};

export default ImportacaoPage;
