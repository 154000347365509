import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import "./App.css";

import Dropdown from 'react-bootstrap/Dropdown';
import AuthService from "./services/auth.service";
import tokenService from "./services/token.service";
import { PrivateRoute } from "./utils/privateRoute";


import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/dashboard/BoardUser";
import BoardModerator from "./components/dashboard/BoardModerator";
import BoardAdmin from "./components/dashboard/BoardAdmin";
import RequestBot from "./components/bot/RequestBot";
import ResponseBot from "./components/bot/ResponseBot";
import EmpresasListar from "./components/empresas/listarEmpresas";
import CadastrarEmpresa from "./components/empresas/cadastrarEmpresa";
// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import ListarUsuarios from "./components/usuarios/listarUsuarios";
import CadastrarUsuario from "./components/usuarios/cadastrarUsuarios"
import ListarPerfis from "./components/usuarios/listarPerfis";
import CadastrarPerfil from "./components/usuarios/cadastrarPerfil";
import ProdutosListar from "./components/produtos/listarProdutos";
import CadastrarProduto from "./components/produtos/cadastrarProdutos";
import CategoriaProdutoListar from "./components/produtos/categoriaProduto";
import CadastrarImportacao from "./components/importacoes/cadastrarImportacoes";
import ListarImportacao from "./components/importacoes/listarImportacoes";
import ListarAvisos from "./components/avisos/listarAvisos"
import userUtils from "./utils/userUtils";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isLogged, setLogged] = useState(false);
  const [isVencido, setIsVencido] = useState(true);

  useEffect(() => {
    const loadPage = () => {
      const user = tokenService.getUserData();
      const userExp = userUtils.checarValidade();

      setIsVencido(userExp.isVencido);
      setLogged(false)

      if (user) {
        setCurrentUser(user);
        setLogged(true);
        setShowModeratorBoard(user.permissoes.includes("ROLE_MODERATOR"));
        setShowAdminBoard(user.permissoes.includes("ROLE_ADMIN"));
      }

      EventBus.on("logout", () => {
        logOut();
      });

    }
    loadPage();
    return () => {
      // EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
    localStorage.removeItem("@elosworld:user");
    localStorage.removeItem("@elosworld:token");
    localStorage.removeItem("@elosworld:ListaAvisosLidos");
  };

  const checkPermission = (requested) => {
    if (currentUser.permissoes.find((pi) => {
      return pi.includes(requested);
    })) {
      return true;
    } else {
      return false;
    }
  }

  const handleValidation = () => {
    return false
  }

  return (
    <div>
      {isLogged && (
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            ElosWorld
          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li>
            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {!isVencido && showAdminBoard && (
              <li className="nav-item">
                <Link to={"/"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}
            {!isVencido && currentUser && checkPermission("acoes-bot") && (
              <Dropdown className="d-inline mx-2">
                <Dropdown.Toggle id="dropdown-autoclose-true" className="simple-btn">
                  Robôs
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  {currentUser && checkPermission("bot-request") && (
                    <Dropdown.Item>
                      <Link to={"/bot/request"} className="drop-link">
                        Executar Robô
                      </Link>
                    </Dropdown.Item>
                  )}
                  {currentUser && checkPermission("bot-response") && (
                    <Dropdown.Item>
                      <Link to={"/bot/response"} className="drop-link">
                        Resultados Robô
                      </Link>
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item>
                    <Link to={"/importacao/listar"} className="drop-link">
                      B2B
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            {
              !isVencido &&

              <Dropdown className="d-inline mx-2">
                <Dropdown.Toggle id="dropdown-autoclose-true" className="simple-btn">
                  Contas e Perfis
                </Dropdown.Toggle>
                <Dropdown.Menu>

                  {!isVencido && currentUser && checkPermission("gerenciar-contas") && (
                    <Dropdown.Item>
                      <Link to={"/contas/listar"} className="drop-link">
                        Gerenciar Contas
                      </Link>
                    </Dropdown.Item>
                  )}
                  {!isVencido && currentUser && checkPermission("gerenciar-perfis") && (
                    <Dropdown.Item>
                      <Link to={"/contas/perfis/listar"} className="drop-link">
                        Gerenciar Perfis
                      </Link>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

            }
            {!isVencido && currentUser && checkPermission("gerenciar-usuarios") && (
              <>
                <li className="nav-item">
                  <Link to={"/usuarios-permissoes/usuarios/listar"} className="nav-link">
                    Usuários
                  </Link>
                </li>
              </>
            )}
            {!isVencido && currentUser && checkPermission("gerenciar-produtos") && (
              <>
                {/* <li className="nav-item">
                  <Link to={"/produto/gerenciar"} className="nav-link">
                    Produtos
                  </Link>
                </li> */}


                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true" className="simple-btn">
                    Produtos
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to={"/produto/gerenciar"} className="drop-link">
                        Gerenciar Produtos
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to={"/produto/categoria/gerenciar"} className="drop-link">
                        Cadastrar Categorias de Produto
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </>
            )}

            {!isVencido && currentUser && checkPermission("gerenciar-avisos") && (
              <>
                <li className="nav-item">
                  <Link to={"/avisos/gerenciar"} className="nav-link">
                    Avisos
                  </Link>
                </li>
              </>
            )}

            {/* <Dropdown className="d-inline mx-2">
                <Dropdown.Toggle id="dropdown-autoclose-true" className="simple-btn">
                  Produtos
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to={"/produto/gerenciar"} className="drop-link">
                      Gerenciar Produtos
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to={"/produto/cadastrar"} className="drop-link">
                      Cadastrar Produto
                    </Link>
                  </Dropdown.Item>                  
                </Dropdown.Menu>
              </Dropdown> */}

          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  ({currentUser.email})
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>
            </div>
          )}
        </nav>
      )}

      {/* <div className="container mt-3"> */}
      <Routes>

        <Route exact path='/' element={<PrivateRoute />}>
          <Route exact path='/' element={<Home />} />
        </Route>
        {/* <Route exact path={"/"} element={<Home />} /> */}
        <Route exact path='/' element={<PrivateRoute />}>
          <Route exact path={"/home"} element={<Home />} />
          {
            !isVencido &&
            <>
              <Route path="/modDashboard" element={<Home />} />
              <Route path="/adminDashboard" element={<Home />} />
              <Route path="/bot/request" element={<RequestBot />} />
              <Route path="/bot/response" element={<ResponseBot />} />
              {/* <Route path="/bot/tiktok/request" element={<TikTokRequestBot />} />
            <Route path="/bot/tiktok/response" element={<TikTokResponseBot />} /> */}
              <Route path="/contas/listar" element={<EmpresasListar />} />
              <Route path="/contas/cadastro" element={<CadastrarEmpresa />} />
              <Route path="/usuarios-permissoes/usuarios/listar" element={<ListarUsuarios />} />
              <Route path="/usuarios-permissoes/usuarios/cadastrar" element={<CadastrarUsuario />} />
              <Route path="/contas/perfis/listar" element={<ListarPerfis />} />
              <Route path="/contas/perfis/cadastrar" element={<CadastrarPerfil />} />
              <Route path="/produto/gerenciar" element={<ProdutosListar />} />
              <Route path="/produto/categoria/gerenciar" element={<CategoriaProdutoListar />} />
              <Route path="/produto/cadastrar" element={<CadastrarProduto />} />
              <Route path="/importacao/cadastrar" element={<CadastrarImportacao />} />
              <Route path="/importacao/listar" element={<ListarImportacao />} />
              <Route path="/avisos/gerenciar" element={<ListarAvisos />} />
            </>
          }
        </Route>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route path="/user" element={<BoardUser />} />
      </Routes>
    </div>

    // </div>
  );
};

export default App;
