import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { FaPencil, FaX, FaBook } from "react-icons/fa6";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "../../style/general.css";
import UserService from "../../services/user.service";
import tokenService from "../../services/token.service";
import AuthService from "../../services/auth.service";
import PermissionUtil from "../../common/PermissionUtil";
import UsuarioModel from "../../models/usuario.model";
import Perfilervice from "../../services/perfil.service";
import HistoricoUsuarioService from "../../services/historicoUsuario.service"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { saveAs } from 'file-saver';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import arrayUtils from "../../utils/arrayUtils";
import dataUtils from "../../utils/dataUtils";
import parse from 'html-react-parser';
import InputMask from 'react-input-mask';

const MySwal = withReactContent(Swal)

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback d-block">
                Campos obrigatorios
            </div>
        );
    }
};

const ListarUsuarios = () => {

    /* userefs */
    const form = useRef();
    const checkBtn = useRef();
    const inputRef = useRef(null);
    const [usuario, setUsuario] = useState("");
    const [usuarioAtualizar, setUsuarioAtualizar] = useState("");
    const [usuariosList, setUsuariosList] = useState([]);
    const [userData, setUserData] = useState(null);


    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [isAdmin, setAdmin] = useState(false);
    const [checkAdmin, setCheckAdmin] = useState(false);

    /** support Modal */
    const [show, setShow] = useState(false);
    const [showAtualizar, setShowAtualizar] = useState(false);
    const handleCloseAtualizar = () => setShowAtualizar(false);
    const handleCloseHistorico = () => setShowHistorico(false);
    const handleCloseExportar = () => setShowExportModal(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowAtualizar = () => setShowAtualizar(true);
    const [authData, setAuth] = useState({});

    /** campos do form */
    const [email, setEmail] = useState(null);
    const [senha, setSenha] = useState(null);
    const [observacao, setObservacao] = useState(null);
    const [observacaoAtualizar, setObservacaoAtualizar] = useState(null);
    const [perfil, setPerfil] = useState({ value: 1000, label: "Cliente" });
    const [nome, setNome] = useState("");
    const [telefone, setTelefone] = useState("");
    const [dataVencimento, setDataVencimento] = useState(new Date());
    const [dataNascimento, setDataNascimento] = useState(new Date());
    const [dataVencimentoArualizar, setDataVencimentoAtualizar] = useState(new Date());
    const [dataNascimentoArualizar, setDataNascimentoAtualizar] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [perfilAtualizar, setPerfilAtualizar] = useState({ id: 1000, titulo: "Cliente" });
    const [nomeAtualizar, setNomeAtualizar] = useState("");
    const [telefoneAtualizar, setTelefoneAtualizar] = useState("");
    const [origemVenda, setOrigemVenda] = useState("");
    const [origemVendaAtualizar, setOrigemVendaAtualizar] = useState("");
    const [uuidEmpresa, setConta] = useState("");
    const [perfilList, setPerfilList] = useState([]);
    const [contasList, setContasList] = useState([]);
    const [descricaoAtualizar, setDescricaoAtualizar] = useState("");
    const [descricao, setDescricao] = useState("");

    const [historicoList, setHistoricoList] = useState([]);
    const [userHistorico, setUserHistorico] = useState([]);

    const [headerList, setHeaderList] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const [showHistorico, setShowHistorico] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);

    const [filtroTipo, setTipoFiltro] = useState("");
    const [filtroValor, setValorFiltro] = useState("");

    const [qtdUserAtivos, setQtdUserAtivos] = useState(0);
    const [qtdUserTotal, setQtdUserTotal] = useState(0);

    const [usuariosListBackup, setUsuariosListBackup] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());


    /** Editor */
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setDescricao(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const onEditorStateAtualizarChange = (editorState) => {
        setEditorState(editorState)
        setDescricaoAtualizar(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    const filtroList = [
        { label: "Nome", value: "nome" },
        { label: "Telefone", value: "telefone" },
        { label: "Data Vencimento", value: "dataVencimento" },
        { label: "E-mail", value: "email" },
        { label: "Status", value: "status" }
    ]


    const statusList = [
        { label: "Ativo", value: "1" },
        { label: "Inativo", value: "2" },
        { label: "Todos", value: "all"}
    ]

    /** estilo dos botoes  */
    const buttonStyle = {
        marginLeft: "15px"
    };
    const buttonStyle2 = {
        marginLeft: "15px",
        height: "35px",
        marginTop: "-13px"
    };

    // listar empresas cadastradas  
    useEffect(() => {
        carregarDadosUsuario();
        carregarUsuarios();


    }, []);


    useEffect(() => {
        const loggedInUser = tokenService.getUserData();
        if (loggedInUser) {
            setAuth(loggedInUser);
            setConta(loggedInUser.uuidEmpresa);
            carregarPerfis(uuidEmpresa);
            if (loggedInUser.permissoes.find((pi) => {
                return pi.includes("super-admin");
            })) {
                // carregar o dropdown
                carregarContas();
                return setAdmin(true);
            } else {
                return setAdmin(false);
            }
        }
    }, []);

    /** recuperar dados do usuario  */
    const carregarDadosUsuario = () => {
        let userData = tokenService.getUserData();
        setUserData(userData);
    }

    /** Metodo para carga da lista de usuarios */
    const carregarUsuarios = () => {
        let adminData = JSON.parse(localStorage.getItem("@elosworld:token"))
        setCheckAdmin(adminData.isAdmin);
        setUsuariosList([]);
        UserService.getUsuarios({ isAdmin: adminData.isAdmin }).then(
            (response) => {
                setUsuariosList(response.data.lista);
                setUsuariosListBackup(response.data.lista);
                let amount = response.data.lista.filter((x) => { return x.status == "1"});
                setQtdUserAtivos(amount.length);
                setQtdUserTotal(response.data.lista.length);                
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
            }
        );
    }
    const handleDelete = (uuid, nomeUsuario, usuario) => {
        MySwal.fire({
            title: "Voce tem certeza?",
            text: `Ao confirmar voce ira remover o usuario ${nomeUsuario} do sistema`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar Remocao"
        }).then((result) => {
            if (result.isConfirmed) {
                AuthService.deleteRemoverUsuario(uuid).then((r) => {
                    if (r.status === 200) {
                        MySwal.fire({
                            title: "Sucesso!",
                            text: "Conta removida",
                            icon: "success"
                        });
                        carregarUsuarios();
                    } else {
                        MySwal.fire({
                            title: "Atencao!",
                            text: `Ocorreu o seguinte erro: ` + r,
                            icon: "error"
                        });
                    }
                }, (error) => {
                    MySwal.fire({
                        title: "Atencao!",
                        text: "Ocorreu o seguinte erro:  " + error,
                        icon: "error"
                    });
                })
            }
        });
    }

    // Funcoes da modal de adicao

    const onRegistrarUsuario = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        let profile;
        if (!perfil.value) {
            // profile = {
            //     value: 1000,
            //     label: "Cliente"
            // }
            profile = 1000; //cliente
        } else {
            profile = perfil.value;
        }
        if (checkBtn.current.context._errors.length === 0) {
            let userData = new UsuarioModel();
            Object.assign(userData, {
                nome: nome,
                telefone: telefone,
                email: email,
                password: senha,
                idPerfil: profile,
                dataVencimento: dataVencimento,
                dataNascimento: dataNascimento,
                observacao: observacao,
                origemVenda: origemVenda,
                status: 1
            })
            AuthService.postCadastrarUsuario(userData).then(
                (dt) => {
                    if (dt.status === 200) {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Sucesso!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'success'
                        });
                        handleClose();
                        carregarUsuarios();
                    } else {
                        setLoading(false);
                        MySwal.fire({
                            title: <strong>Atencao!</strong>,
                            html: <i>{dt.data.message}</i>,
                            icon: 'warning'
                        })
                    }

                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    }

    function formatDateToDDMMYYYY(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1; // Months are zero-based      
        const y = date.getFullYear();

        return `${d < 10 ? '0' : ''}${d}/${m < 10 ? '0' : ''}${m}/${y}`;
    }

    const onAtualizar = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        let userData = new UsuarioModel();
        Object.assign(usuarioAtualizar, {
            nome: nomeAtualizar ? nomeAtualizar : usuarioAtualizar.nome,
            telefone: telefoneAtualizar ? telefoneAtualizar : usuarioAtualizar.telefone,
            email: usuarioAtualizar.email,
            password: usuarioAtualizar.senha ? usuarioAtualizar.senha : usuarioAtualizar.password,
            idPerfil: perfil.value ? perfil.value : usuarioAtualizar.idPerfil,
            dataVencimento: new Date(dataVencimentoArualizar),
            dataNascimento: new Date(dataNascimentoArualizar),
            observacao: observacaoAtualizar,
            origemVenda: origemVendaAtualizar,
            status: 1
        })
        AuthService.putAtualizarUsuario(usuarioAtualizar).then(
            (dt) => {
                if (dt.status === 200) {
                    setLoading(false);
                    MySwal.fire({
                        title: <strong>Sucesso!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'success'
                    });
                    handleCloseAtualizar();
                    carregarUsuarios();
                } else {
                    setLoading(false);
                    MySwal.fire({
                        title: <strong>Atencao!</strong>,
                        html: <i>{dt.data.message}</i>,
                        icon: 'warning'
                    })
                }

            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    /** filtrar */
    const onFiltrar = (e) => {
        if (filtroValor !== "") {
            const filterBase = usuariosListBackup;
            const filtered = filterBase.filter((dt) => {
                if (filtroTipo.value === 'nome' || filtroTipo.value === 'telefone' || filtroTipo.value === "email" ) {
                    if (dt[filtroTipo.value] !== null) {
                        return dt[filtroTipo.value].toLowerCase().includes(filtroValor.toLowerCase());
                    }
                } else if (filtroTipo.value === 'dataVencimento') {
                    if (dt[filtroTipo.value] !== null) {
                        const ndt = new Date(dt[filtroTipo.value]);
                        return ndt.getFullYear() === new Date(filtroValor).getFullYear() &&
                            ndt.getMonth() === new Date(filtroValor).getMonth() &&
                            ndt.getDate() === new Date(filtroValor).getDate();
                    }
                } else if(filtroTipo.value === "status"){
                    console.log(filtroValor)
                    console.log(dt);
                    console.log(filtroTipo)
                    if(filtroValor.value !== null) {
                        if(filtroValor.value == "todos"){
                            setUsuariosList(usuariosListBackup);
                        } else {
                            return dt[filtroTipo.value] === filtroValor.value
                        }
                    }
                    // if (dt[filtroTipo.value] !== null) {
                    //     return dt[filtroTipo.value].toLowerCase().includes(filtroValor.toLowerCase());
                    // }
                }
                return false;
            });
            setUsuariosList(filtered);
        } else {
            setUsuariosList(usuariosListBackup);
        }
    };

    /** Listar perfis */
    const carregarPerfis = (uuidConta) => {
        setPerfilList([]);
        setPerfil({})
        Perfilervice.getPerfil(uuidConta).then(
            (dt) => {
                let perfilArray = [];
                dt.data.lista.forEach((f) => {
                    perfilArray.push({ label: f.titulo, value: f.id })
                })
                // setPerfilList(dt.data.lista);
                setPerfilList(perfilArray);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    /** carregar contas e recarregar perfis */
    const carregarContas = () => {
        AuthService.getContas().then(
            (dt) => {
                setContasList(dt.data.lista);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    }

    /** Exportar lista  */
    // const onExportar = () => {
    //     const headers = Object.keys(usuariosList[0]); // assume que a primeira linha tem as mesmas colunas que as demais
    //     const csv = [headers.join(',')].concat( // adiciona a linha de cabeçalho
    //         usuariosList.map(row => Object.values(row).join(','))
    //     ).join('\n');

    //     const blob = new Blob([csv], { type: 'text/csv' });
    //     saveAs(blob, 'elosworld-usuarios.csv');
    // }
    const onExportar = () => {
        setShowExportModal(true);

        const columnsToExport = []; // array to store columns to export
        const headers = Object.keys(usuariosList[0]); // assume que a primeira linha tem as mesmas colunas que as demais

        // create a checkbox for each column
        // headers.forEach((header, index) => {
        //   const checkbox = document.createElement('input');
        //   checkbox.type = 'checkbox';
        //   checkbox.id = `checkbox-${index}`;
        //   checkbox.checked = true; // default to checked
        //   columnsToExport.push({ header, checkbox });
        //   const label = document.createElement('label');
        //   label.htmlFor = checkbox.id;
        //   label.textContent = header;
        //   modal.querySelector('.column-selection').appendChild(checkbox);
        //   modal.querySelector('.column-selection').appendChild(label);
        // });


        setHeaderList(headers);


        // add a button to export

        // exportButton.onclick = () => {
        //   const selectedColumns = columnsToExport.filter(({ checkbox }) => checkbox.checked);
        //   const csv = [selectedColumns.map(({ header }) => header).join(',')].concat(
        //     usuariosList.map((row) => selectedColumns.map(({ header }) => row[header]).join(','))
        //   ).join('\n');

        //   const blob = new Blob([csv], { type: 'text/csv' });
        //   saveAs(blob, 'elosworld-usuarios.csv');
        // };

    };

    const exportarDados = () => {
        debugger
        //   const selectedColumns = columnsToExport.filter(({ checkbox }) => checkbox.checked);
        const csv = [selectedColumns.map((header) => header).join(',')].concat(
            usuariosList.map((row) => selectedColumns.map((header) => row[header]).join(','))
        ).join('\n');

        const blob = new Blob([csv], { type: 'text/csv' });
        saveAs(blob, 'elosworld-usuarios.csv');
    }

    /** carregar lista de hisotrico */
    const carregarHistorico = (user) => {
        let userData = tokenService.getUserData();

        let userHistModel;

        if (userHistorico.uuid) {
            userHistModel = {
                "idRemetente": userData.uuid,
                "idUsuario": userHistorico.uuid
            }
        } else {
            userHistModel = {
                "idRemetente": userData.uuid,
                "idUsuario": user.uuid
            }
        }

        HistoricoUsuarioService.getListarHistorico(userHistModel).then(
            (response) => {
                setHistoricoList(response.data.lista);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    /* Quando houver alteracao no campo */
    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    }
    const onChangeSenha = (e) => {
        const senha = e.target.value;
        setSenha(senha);
    }
    const onChangeObservacao = (e) => {
        const obs = e.target.value;
        setObservacao(obs);
    }
    const onChangeObservacaoAtualizar = (e) => {
        const obs = e.target.value;
        setObservacaoAtualizar(obs);
    }
    const onChangeOrigemVendaAtualizar = (e) => {
        const obs = e.target.value;
        setOrigemVendaAtualizar(obs);
    }
    const onChangeOrigemVenda = (e) => {
        const obs = e.target.value;
        setOrigemVenda(obs);
    }
    const onChangeNome = (e) => {
        const nome = e.target.value;
        setNome(nome);
    }
    const onChangeTelefone = (e) => {
        const telefone = e.target.value;
        setTelefone(telefone);
    }
    const onChangeSenhaAtualizar = (e) => {
        const senha = e.target.value;
        setSenha(senha);
    }
    const onChangeNomeAtualizar = (e) => {
        const nm = e.target.value;
        setNomeAtualizar(nm);
    }
    const onChangeTelefoneAtualizar = (e) => {
        const tl = e.target.value;
        setTelefoneAtualizar(tl);
    }

    const onChangeVencimentoAtualizar = (e) => {
        setDataVencimentoAtualizar(String(e));
    }

    const onChangeNascimentoAtualizar = (e) => {
        setDataNascimentoAtualizar(String(e));
    }

    const adicionarHistorico = () => {
        let hist = {
            "idUsuario": userHistorico.uuid,
            "idRemetente": userData.uuid,
            "descricao": descricaoAtualizar,
            "dataMensagem": new Date()
        }

        HistoricoUsuarioService.registrarHistorico(hist).then(
            (response) => {
                carregarHistorico();
                setEditorState(EditorState.createEmpty());

            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    const removerHistorico = (his) => {
        HistoricoUsuarioService.removerHistorico(his).then(
            (response) => {
                carregarHistorico();
                setEditorState(EditorState.createEmpty());

            },
            (error) => {
                const _content =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    const fecharHistorico = () => {
        setUserHistorico("");
        setHistoricoList([]);
        setShowHistorico(false);

    }

    return (
        <div className="container-fluid">
            <header className="jumbotron">
                <div className="row">
                    <div className="col-9">
                        <h3>Gerenciar Usuários</h3>
                        Você possui {qtdUserAtivos} / {qtdUserTotal} Usuários Ativos 
                    </div>
                    {
                        PermissionUtil.checkPermission("gerenciar-empresas", userData) && (
                            <div className="col-3">
                                <button className="btn btn-primary btn-block" onClick={() => {
                                    handleShow()
                                }}>
                                    <span>
                                        Registrar Novo
                                    </span>
                                </button>
                            </div>
                        )
                    }
                </div>
            </header>
            <Form>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-3">
                        <div className="form-group">
                            <Select options={filtroList} onChange={(e) => setTipoFiltro(e)} />
                        </div>
                    </div>
                    <div className="col-3">
                        {
                            filtroTipo.value === 'dataVencimento' &&
                            <div className="form-group">
                                <DatePicker className="custom-datepicker" dateFormat="dd/MM/yyyy" selected={filtroValor} onChange={setValorFiltro} />
                            </div>
                        }
                        {
                            filtroTipo.value !== 'dataVencimento' && filtroTipo.value !== 'status' &&
                            <div className="form-group">
                                <Input
                                    type="text"
                                    value={filtroValor}
                                    onChange={(e) => setValorFiltro(e.target.value)}
                                    placeholder="Enter filter value"
                                    className="form-control custom-input"
                                />
                            </div>
                        }
                        {
                            filtroTipo.value === 'status' &&
                            <div className="form-group">
                                <Select options={statusList} onChange={(e) => { setValorFiltro(e)}} />
                            </div>
                        }

                    </div>
                    <div className="col-1 text-right mb-3" style={{ marginLeft: "-2%" }}>
                        <Button variant="primary" className="btn-custom" onClick={onFiltrar}>
                            Filtrar
                        </Button>
                    </div>
                    <div className="col-1 text-right mb-3" style={{ marginLeft: "-2%" }}>
                        <Button variant="warning" className="btn-custom" style={{ color: "#FFFFFF" }} onClick={onExportar}>
                            Exportar
                        </Button>
                    </div>
                </div>
            </Form>

            <div className="row">
                <div className="col-12 text-center">
                    {
                        usuariosList.length === 0 &&
                        <h4 style={{ color: "#000000" }}>Nenhum Usuario encontrado.</h4>
                    }
                    {usuariosList.length > 0 &&
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Perfil</th>
                                    <th>Telefone</th>
                                    <th>Origem Venda</th>
                                    <th>Data Validade</th>
                                    <th>Data Cadastro</th>
                                    <th>Status</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    usuariosList.map((user, index) => {
                                        return (
                                            <>
                                                <tr key={index + 1}>
                                                    <td>{user.nome}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.perfil}</td>
                                                    <td>{user.telefone}</td>
                                                    <td>{user.origemVenda}</td>
                                                    <td>{user.dataVencimento ? new Date(user.dataVencimento).getDate() + "/" + (new Date(user.dataVencimento).getMonth() + 1) + "/" + new Date(user.dataVencimento).getFullYear() : "Não definido"}</td>
                                                    <td>{user.createdAt ? new Date(user.createdAt).getDate() + "/" + (new Date(user.createdAt).getMonth() + 1) + "/" + new Date(user.createdAt).getFullYear() : "Não definido"}</td>
                                                    <td>{user.status === "1" ? 'Ativo' : user.status === "2" ? 'Bloqueado' : 'Inativo'}</td>
                                                    <td>
                                                        {
                                                            userData.uuid !== user.uuid &&
                                                            <Button ref={inputRef} variant="danger" onClick={() => {
                                                                handleDelete(user.uuid, user.email, user)
                                                            }}>
                                                                <FaX />
                                                            </Button>
                                                        }
                                                        {
                                                            userData.uuid !== user.uuid &&

                                                            <Button style={buttonStyle} ref={inputRef} variant="success" onClick={() => {
                                                                // setNomeFantasia(empresa.nomeFantasia);
                                                                // setUsuarioInsta(empresa.usuarioInstagram);
                                                                // setUUIDEmpresa(empresa.uuidEmpresa);
                                                                setUsuarioAtualizar(user);
                                                                setNomeAtualizar(user.nome);
                                                                setTelefoneAtualizar(user.telefone);
                                                                setDataNascimentoAtualizar(user.dataNascimento)
                                                                handleShowAtualizar()
                                                            }}>
                                                                <FaPencil />
                                                            </Button>

                                                        }
                                                        <Button style={buttonStyle} ref={inputRef} variant="primary" onClick={() => {
                                                            setUserHistorico(user);
                                                            setHistoricoList([]);
                                                            carregarHistorico(user);
                                                            setShowHistorico(true);
                                                        }}>
                                                            <FaBook />
                                                        </Button>


                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
            {/* Criar novo Usuario */}
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Cadastrar Novo Usuário
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onRegistrarUsuario} ref={form}>
                        <div className="form-group">
                            <label htmlFor="nome">Nome</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nome"
                                value={nome}
                                onChange={onChangeNome}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Telefone</label>

                            <InputMask mask="(99)99999-9999" value={telefone} onChange={onChangeTelefone}>

                            </InputMask>
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={onChangeEmail}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Senha</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={senha}
                                onChange={onChangeSenha}
                                validations={[required]}
                            />
                        </div>
                        {
                            checkAdmin &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="observacao">Observação</label>
                                    <textarea
                                        name="postContent"
                                        className="form-control"
                                        rows={4}
                                        cols={57}
                                        value={observacao}
                                        onChange={onChangeObservacao}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="origem">Origem Venda</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="origemVenda"
                                        value={origemVenda}
                                        onChange={onChangeOrigemVenda}
                                    // validations={[required]}
                                    />
                                </div>
                            </>
                        }
                        <div className="form-group">
                            <label htmlFor="dataVencimento">Data Vencimento</label>
                            <br></br>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={dataVencimento} onChange={(date) => setDataVencimento(date)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dataVencimento">Data Nascimento</label>
                            <br></br>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={dataNascimento} onChange={(date) => setDataNascimento(date)} />
                        </div>
                        {isAdmin &&
                            < div className="form-group">
                                <label htmlFor="tipo">Conta Relacionada</label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success">
                                        {uuidEmpresa.NomeConta ? uuidEmpresa.NomeConta : "Selecione"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* Loop da lista */}
                                        {
                                            contasList.map((conta, index) => {
                                                return (
                                                    <>
                                                        <Dropdown.Item key={index + conta.uuid} href="#" onClick={() => {
                                                            setConta(conta)
                                                            carregarPerfis(conta.uuid)
                                                        }} value={conta}>
                                                            {conta.NomeConta}
                                                        </Dropdown.Item>
                                                    </>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                        < div className="form-group">
                            <label htmlFor="tipo">Perfil</label>
                            <Select options={perfilList} onChange={(e) => setPerfil(e)} defaultValue={perfilList[0]} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onRegistrarUsuario}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Atualizar usuario */}
            <Modal show={showAtualizar} onHide={handleCloseAtualizar} size="lg" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Editar Usuário -  {usuarioAtualizar.email}
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={handleClose}> X </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={form}>
                        <div className="form-group">
                            <label htmlFor="nome">Nome</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="nome"
                                value={nomeAtualizar}
                                onChange={onChangeNomeAtualizar}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="telefone">Telefone</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="telefone"
                                value={telefoneAtualizar}
                                onChange={onChangeTelefoneAtualizar}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={usuarioAtualizar.email}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Senha</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={usuarioAtualizar.senha}
                                onChange={onChangeSenhaAtualizar}
                                validations={[required]}
                            />
                        </div>
                        <p style={{ color: "#000000" }}> Perfil Atual:
                            <br />
                            <strong>
                                {usuarioAtualizar.perfil}
                            </strong>
                        </p>
                        <div className="form-group">
                            <label htmlFor="dataVencimento">Data Vencimento</label>
                            <br></br>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={dataVencimentoArualizar} onChange={onChangeVencimentoAtualizar} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dataNascimento">Data Nascimento</label>
                            <br></br>
                            <DatePicker dateFormat="dd/MM/yyyy" selected={dataNascimento} onChange={onChangeNascimentoAtualizar} />
                        </div>
                        {
                            checkAdmin &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="observacao">Observação</label>
                                    <textarea
                                        name="postContent"
                                        className="form-control"
                                        rows={4}
                                        cols={57}
                                        value={usuarioAtualizar.observacao}
                                        onChange={onChangeObservacaoAtualizar}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="origem">Origem Venda</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="origemVenda"
                                        value={usuarioAtualizar.origemVenda}
                                        onChange={onChangeOrigemVendaAtualizar}
                                    // validations={[required]}
                                    />
                                </div>
                            </>
                        }
                        {isAdmin &&
                            < div className="form-group">
                                <label htmlFor="tipo">Conta Relacionada</label>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success">
                                        {uuidEmpresa.NomeConta ? uuidEmpresa.NomeConta : "Selecione"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* Loop da lista */}
                                        {
                                            contasList.map((conta, index) => {
                                                return (
                                                    <>
                                                        <Dropdown.Item key={index + conta.uuid} href="#" onClick={() => {
                                                            setConta(conta)
                                                            carregarPerfis(conta.uuid)
                                                        }} value={conta}>
                                                            {conta.NomeConta}
                                                        </Dropdown.Item>
                                                    </>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                        < div className="form-group">
                            <label htmlFor="tipo">Perfil</label>
                            <Select options={perfilList} onChange={(e) => setPerfil(e)} />
                        </div>
                        {message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAtualizar}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={onAtualizar}>
                        Atualizar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Historico usuario */}
            <Modal show={showHistorico} onHide={handleCloseHistorico} size="xl" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Historico Usuario
                        </h4>
                    </Modal.Title>
                    <Button className="col-md-2 mdl-close-btn" onClick={fecharHistorico}> X </Button>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <div className="hist-container">
                            {
                                historicoList.length > 0 ? historicoList.map((historico, index) => {
                                    return (
                                        <div className="userhist-item">
                                            <Row>
                                                <Col md={3} className="hora-hist">
                                                    {dataUtils.formatarDataHora(historico.dataMensagem)}
                                                </Col>
                                                <Col md="8"></Col>
                                                <Col md="1" >

                                                    <Button style={buttonStyle2} ref={inputRef} variant="transparent" onClick={() => {
                                                        removerHistorico(historico);
                                                    }}>X
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row mt={3} style={{ marginTop: "15px" }}>
                                                <Col md="8" className="desc-hist">
                                                    {parse(historico.descricao)}
                                                </Col>
                                                <Col md="3">
                                                </Col>
                                                <Col md="1">
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }) :
                                    <h4 className="text-center">
                                        Nenhum Historico localizado
                                    </h4>
                            }
                        </div>
                        <hr></hr>
                        <Row>
                            <Col md="12">
                                <Form>
                                    <div className="form-group">
                                        {/* <label htmlFor="descricao">Descrição</label> */}
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={onEditorStateAtualizarChange}
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={adicionarHistorico}>
                        Adicionar Historico
                    </Button>
                    <Button variant="secondary" onClick={fecharHistorico}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*  Modal de exportacao */}
            <Modal show={showExportModal} onHide={handleCloseExportar} size="xl" backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{ color: "#000000" }}>
                        <h4>
                            Exportar Usuários
                        </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <label style={{ color: "#000000" }}>
                            Selecione as colunas a serem exportadas:
                        </label>
                        <Row>
                            {
                                headerList.length > 0 ? headerList.map((header, index) => {
                                    return (
                                        <Col md={3}>
                                            <input type="checkbox" style={{ marginRight: "10px" }} checked={selectedColumns.includes(header)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedColumns([...selectedColumns, header]);
                                                    } else {
                                                        setSelectedColumns(
                                                            selectedColumns.filter(
                                                                (col) => col !== header
                                                            )
                                                        );
                                                    }
                                                }} />
                                            <label style={{ color: 'black' }}>{header}</label>
                                        </Col>
                                    )
                                }) :
                                    <h4 className="text-center">
                                        Nenhum Header localizado
                                    </h4>
                            }
                        </Row>
                        {/* setSelectedColumns                         */}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={exportarDados}>
                        Exportar
                    </Button>
                    <Button variant="secondary" onClick={handleCloseExportar}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );


}

export default ListarUsuarios;