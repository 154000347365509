import axios from "axios";

const register = (username, email, password) => {
  return axios.post(process.env.REACT_APP_API_URL + "signup", {
    username,
    email,
    password,
  });
};

const getContas = () => {
  return axios.get(process.env.REACT_APP_API_URL + "usuario/listar/contas");
};

const postCadastrarUsuario = (dadosUser) => {
  return axios.post(process.env.REACT_APP_API_URL + "usuario/criar", dadosUser);
}

const postCadastrarUsuarioOut = (dadosUser) => {
  return axios.post(process.env.REACT_APP_API_URL + "usuario/criar-usuario", dadosUser);
}

const putAtualizarUsuario = (dadosUser) => {
  return axios.put(process.env.REACT_APP_API_URL + "usuario/atualizar", dadosUser);
}

const deleteRemoverUsuario = (uuid) => {
  return axios.delete(process.env.REACT_APP_API_URL + "usuario/remover/"+uuid);
}

const login = (username, password) => {
  let userdata = {
    "email": username,
    "password": password
  };
  return axios
    .post(process.env.REACT_APP_API_URL + "usuario/autenticar",userdata)
    .then((response) => {      
      if(response.message == "Nenhum usuario encontrado"){
        response.userfound = false;
        throw new ("Nenhum usuario encontrado")
      }
      if (response.data.email) {
        response.userfound = true;
        localStorage.setItem("@elosworld:user", JSON.stringify(response.data.email));
        localStorage.setItem("@elosworld:token", JSON.stringify(response.data));
      }

      return response;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post(process.env.REACT_APP_API_URL + "signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("@elosworld:user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getContas,
  postCadastrarUsuario,
  postCadastrarUsuarioOut,
  putAtualizarUsuario,
  deleteRemoverUsuario
}

export default AuthService;
