import axios from "./BaseService";

/**
 * listar importacoes ativas
 */
const getListarUm = (id) => {
    return axios.get(process.env.REACT_APP_API_URL + "importacao/listar/" + id);
}

/**
 * listar todas as importacoes
 */
// const getListarTodos = (page, limit, filters) => {
//     return axios.get(process.env.REACT_APP_API_URL + "importacao/listartodos/?page=" + page + "&limit=" + limit + "&filters=" + JSON.stringify(filters));
// }

const getListarTodos = (page, limit, filters, noPagination = false) => {
    return axios.get(process.env.REACT_APP_API_URL + "importacao/listartodos", {
        params: { page, limit, filters: JSON.stringify(filters), noPagination }
    });
}

/**
 * Registrar nova importacao
 */
const postRegistrarImportacao = (importacaoData) => {
    return axios.post(process.env.REACT_APP_API_URL + "importacao/registrar", importacaoData);
}

/**
 * atualizar importacao
 */
const putAtualizarImportacao = (importacaoData) => {
    return axios.put(process.env.REACT_APP_API_URL + "importacao/atualizar", importacaoData);
}

/**
 * remover importacao
 */
const deleteRemoverImportacao = (importacaoData) => {
    return axios.delete(process.env.REACT_APP_API_URL + "importacao/remover/" + importacaoData);
}


const postUploadChunk = async (chunk) => {
    return axios.post(process.env.REACT_APP_API_URL + "importacao/registrar", chunk);
}

const updateCEPImportacao = async () => {
    return axios.put(process.env.REACT_APP_API_URL + "importacao/atualizarcep");
}

const ImportacaoService = {
    postUploadChunk,
    getListarUm,
    getListarTodos,
    postRegistrarImportacao,
    putAtualizarImportacao,
    deleteRemoverImportacao,
    updateCEPImportacao
}

export default ImportacaoService;